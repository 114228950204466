import { createContext } from 'react';

function noop() {};

export const LanguageContext = createContext({
  language: null,
  setTsna: noop,
  showToastMessage: noop,
});


/* eslint-disable no-unused-vars */
import { useState, useCallback, useEffect, useRef, useContext } from 'react';
import cn from 'classnames';

import Playback from './Controls/Playback';
import Skip from './Controls/Skip';
import Rewind from './Controls/Rewind';
import Volume from './Controls/Volume';
import Progress from './Controls/Progress';
import Time from './Controls/Time';
import Pip from './Controls/Pip';
import Fullscreen from './Controls/Fullscreen';
import Subtitles from './Controls/Subtitles';
import Settings from './Controls/Settings';
import Dropdown from './Controls/Dropdown';
import Loader from './UI/Loader/Loader';
import KeyAction from './UI/KeyAction/KeyAction';
import Error from './UI/Error/Error';
import { useTimeout } from '../../hooks/timer.hook';
import { useLocalStorage } from '../../hooks/storage.hook';
import { formatTime } from '../../util/format';
import { LanguageContext } from '../../context/LanguageContext';
import { config } from "../../config/config";
import './Player.css';

const Player = ({
  src, videoRef, autoPlay, currentProgress, setCurrentProgress, resolutions,
  currentResolution, setCurrentResolution, currentTimeLine, setCurrentTimeLine,
  thumbnail, setThumbnail, allowRewind, video, card
}) => {
  const { language } = useContext(LanguageContext);
  const { languagesLocale } = config;

  const [displayControls, setDisplayControls] = useState(true);
  const [playbackState, setPlaybackState] = useState(false);
  const [volumeState, setVolumeState] = useLocalStorage('video-volume', 1);
  const [bufferProgress, setBufferProgress] = useState(0);
  const [seekProgress, setSeekProgress] = useState(0);
  const [videoDuration, setVideoDuration] = useState(0);
  const [seekTooltip, setSeekTooltip] = useState('00:00');
  const [seekTooltipPosition, setSeekTooltipPosition] = useState('');
  const [currentTimeUI, setCurrentTimeUI] = useState('00:00');
  const [remainedTimeUI, setRemainedTimeUI] = useState('00:00');
  const [pipState, setPipState] = useState(false);
  const [fullscreenState, setFullscreenState] = useState(false);
  const [displayDropdown, setDisplayDropdown] = useState(false);
  const [playbackRates] = useState([0.5, 0.75, 1, 1.25, 1.5]);
  const [activePlaybackRate, setActivePlaybackRate] = useLocalStorage(
    'video-playbackrate',
    1
  );
  const [displayLoader, setDisplayLoader] = useState(true);
  const [volumeKeyAction, setvolumeKeyAction] = useState(false);
  const [videoError, setVideoError] = useState(null);
  const [currentTimeProgress, setCurrentTimeProgress] = useState(0);
  const [subtitlesState, setSubtitlesState] = useLocalStorage('subtitles', true);
  const [subtitleUrl, setSubtitleUrl] = useState(null);
  const [activeCue, setActiveCue] = useState(null);
  const [track, setTrack] = useState(null);
  const [subtitlesReady, setSubtitlesReady] = useState(false);

  const videoContainerRef = useRef(null);
  const videoKeyActionRef = useRef(null);
  const forward = (new URL(window.location.href).searchParams.get('forward') === 'on') || null;

  useEffect(() => {
    const video = videoRef.current;

    const updateProgress = () => {
      const currentTime = video.currentTime;
      setCurrentTimeProgress(currentTime);
    };

    video.addEventListener('timeupdate', updateProgress);

    return () => {
      video.removeEventListener('timeupdate', updateProgress);
    };
  }, [videoRef]);

  const playPromise = useRef();
  const volumeData = useRef(volumeState || 1);
  const progressSeekData = useRef(0);

  const [setControlsTimeout] = useTimeout();
  const [setKeyActionVolumeTimeout] = useTimeout();
  const [setLoaderTimeout, clearLoaderTimeout] = useTimeout();

  const hideControlsHandler = useCallback(() => {
    const video = videoRef?.current;

    if (video.paused) {
      return;
    }

    setDisplayControls(false);
  }, [videoRef]);

  const showControlsHandler = useCallback(() => {
    const video = videoRef?.current;

    setDisplayControls(true);

    if (video.paused) {
      return;
    }

    setControlsTimeout(() => {
      hideControlsHandler();
    }, 2000);
  }, [hideControlsHandler, setControlsTimeout, videoRef]);

  const togglePlayHandler = useCallback(() => {
    const video = videoRef?.current;

    if (video.paused || video.ended) {
      playPromise.current = video.play();
      setThumbnail('');
      return;
    }

    if (!playPromise.current) {
      return;
    }

    playPromise.current.then(() => {
      video.pause();
    });
  }, [setThumbnail, videoRef]);

  const videoPlayHandler = useCallback(() => {
    setPlaybackState(true);
    showControlsHandler();
  }, [showControlsHandler]);

  const videoPauseHandler = useCallback(() => {
    setPlaybackState(false);
    showControlsHandler();
  }, [showControlsHandler]);

  const showLoaderHandler = useCallback(() => {
    setLoaderTimeout(() => setDisplayLoader(true), 300);
  }, [setLoaderTimeout]);

  const hideLoaderHandler = useCallback(() => {
    clearLoaderTimeout();
    setDisplayLoader(false);
  }, [clearLoaderTimeout]);

  const volumeInputHandler = useCallback(
    (event) => {
      const video = videoRef?.current;

      video.volume = +event.target.value;
    },
    [videoRef]
  );

  const volumeChangeHandler = useCallback(() => {
    const video = videoRef?.current;

    setVolumeState(video.volume);

    if (video.volume === 0) {
      video.muted = true;
    } else {
      video.muted = false;
      volumeData.current = video.volume;
    }
  }, [setVolumeState, videoRef]);

  const toggleMuteHandler = useCallback(() => {
    const video = videoRef?.current;

    if (video.volume !== 0) {
      volumeData.current = video.volume;
      video.volume = 0;
      setVolumeState(0);
    } else {
      video.volume = volumeData.current;
      setVolumeState(volumeData.current);
    }
  }, [setVolumeState, videoRef]);

  const timeChangeHandler = useCallback(() => {
    const video = videoRef?.current;

    const duration = video.duration || 0;
    const currentTime = video.currentTime || 0;
    const buffer = video.buffered;

    setCurrentProgress((currentTime / duration) * 100);
    setSeekProgress(currentTime);

    if (duration > 0) {
      for (let i = 0; i < buffer.length; i++) {
        if (
          buffer.start(buffer.length - 1 - i) === 0 ||
          buffer.start(buffer.length - 1 - i) < video.currentTime
        ) {
          setBufferProgress(
            (buffer.end(buffer.length - 1 - i) / duration) * 100
          );
          break;
        }
      }
    }

    if (currentTime) {
      const formattedCurrentTime = formatTime(Math.round(currentTime));
      const formattedRemainedTime = formatTime(
        Math.round(duration) - Math.round(currentTime)
      );

      setCurrentTimeUI(formattedCurrentTime);
      setRemainedTimeUI(formattedRemainedTime);
    }
  }, [setCurrentProgress, videoRef]);

  const seekMouseMoveHandler = useCallback((event) => {
    const video = videoRef?.current;

    const rect = event.currentTarget.getBoundingClientRect();
    const skipTo = (event.nativeEvent.offsetX / rect.width) * video.duration;

    progressSeekData.current = skipTo;

    let formattedTime;

    if (skipTo > video.duration) {
      formattedTime = formatTime(video.duration);
    } else if (skipTo < 0) {
      formattedTime = '00:00';
    } else {
      formattedTime = formatTime(skipTo);
      setSeekTooltipPosition(`${event.nativeEvent.offsetX}px`);
    }

    setSeekTooltip(formattedTime);
  }, [videoRef]);

  const seekInputHandler = useCallback(
    (event) => {
      const video = videoRef?.current;

      const skipTo = progressSeekData.current || +event.target.value;

      video.currentTime = skipTo;
      setCurrentProgress((skipTo / video.duration) * 100);
      setSeekProgress(skipTo);
    },
    [setCurrentProgress, videoRef]
  );

  const rewindHandler = useCallback(() => {
    const video = videoRef?.current;

    video.currentTime -= 10;

    const rewindContainer = videoKeyActionRef?.current?.rewind;
    const rewindElement = rewindContainer?.firstElementChild;

    rewindContainer?.animate(
      [{ opacity: 0 }, { opacity: 1 }, { opacity: 1 }, { opacity: 0 }],
      {
        duration: 1000,
        easing: 'ease-out',
        fill: 'forwards',
      }
    );
    rewindElement?.animate(
      [
        { opacity: 1, transform: 'translateX(0)' },
        { opacity: 0, transform: `translateX(-20%)` },
      ],
      {
        duration: 1000,
        easing: 'ease-in-out',
        fill: 'forwards',
      }
    );
  }, [videoRef]);

  const skipHandler = useCallback(() => {
    const video = videoRef?.current;

    video.currentTime += 10;

    const forwardContainer = videoKeyActionRef?.current?.skip;
    const forwardElement = forwardContainer?.firstElementChild;

    forwardContainer?.animate(
      [{ opacity: 0 }, { opacity: 1 }, { opacity: 1 }, { opacity: 0 }],
      {
        duration: 1000,
        easing: 'ease-out',
        fill: 'forwards',
      }
    );
    forwardElement?.animate(
      [
        { opacity: 1, transform: 'translateX(0)' },
        { opacity: 0, transform: `translateX(20%)` },
      ],
      {
        duration: 1000,
        easing: 'ease-in-out',
        fill: 'forwards',
      }
    );
  }, [videoRef]);

  const togglePipHandler = useCallback(() => {
    if (document.pictureInPictureElement) {
      document.exitPictureInPicture();
    } else {
      videoRef?.current.requestPictureInPicture();
    }
  }, [videoRef]);

  const pipEnterHandler = useCallback(() => {
    setPipState(true);
  }, []);

  const pipExitHandler = useCallback(() => {
    setPipState(false);
  }, []);

  const toggleFullscreenHandler = useCallback(() => {
    if (document.fullscreenElement) {
      document.exitFullscreen();
    } else {
      videoContainerRef?.current?.requestFullscreen();
    }
  }, []);

  const fullscreenChangeHandler = useCallback(() => {
    if (document.fullscreenElement) {
      setFullscreenState(true);
    } else {
      setFullscreenState(false);
    }
  }, []);

  const toggleDropdownHandler = useCallback(() => {
    setDisplayDropdown((prev) => !prev);
  }, []);

  const changePlaybackRateHandler = useCallback(
    (playbackRate) => {
      const video = videoRef?.current;

      video.playbackRate = playbackRate;
      setActivePlaybackRate(playbackRate);
    },
    [setActivePlaybackRate, videoRef]
  );

  const keyEventHandler = useCallback(
    (event) => {
      const video = videoRef?.current;
      const activeElement = document.activeElement;

      if (
        !activeElement ||
        (activeElement.localName === 'input' &&
          (activeElement).type !== 'range') ||
        activeElement.localName === 'textarea'
      ) {
        return;
      }

      const { key } = event;

      switch (key) {
        case 'ArrowLeft':
          event.preventDefault();
          rewindHandler();
          break;
        case 'ArrowRight':
          if (allowRewind || forward) {
            event.preventDefault();
            skipHandler();
          }
          break;
        case 'ArrowUp':
          event.preventDefault();
          if (video.volume + 0.05 > 1) {
            video.volume = 1;
          } else {
            video.volume = +(video.volume + 0.05).toFixed(2);
          }

          setvolumeKeyAction(true);
          setKeyActionVolumeTimeout(() => {
            setvolumeKeyAction(false);
          }, 1500);

          break;
        case 'ArrowDown':
          event.preventDefault();
          if (video.volume - 0.05 < 0) {
            video.volume = 0;
          } else {
            video.volume = +(video.volume - 0.05).toFixed(2);
          }

          setvolumeKeyAction(true);
          setKeyActionVolumeTimeout(() => {
            setvolumeKeyAction(false);
          }, 1500);

          break;
        case ' ':
          event.preventDefault();
          togglePlayHandler();
          break;
        default:
          event.preventDefault();
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [videoRef, rewindHandler, setKeyActionVolumeTimeout, togglePlayHandler]
  );

  const videoLoadedHandler = useCallback(() => {
    const video = videoRef?.current;
    video.volume = volumeState;
    video.playbackRate = activePlaybackRate;
    video.currentTime = currentTimeLine;

    if (video?.textTracks?.length) {
      video.textTracks[0].mode = 'disabled';
      video.textTracks[0].mode = 'showing';
    }

    setVideoDuration(video.duration);
    timeChangeHandler();

    video.addEventListener('enterpictureinpicture', pipEnterHandler);
    video.addEventListener('leavepictureinpicture', pipExitHandler);
    document.addEventListener('keydown', keyEventHandler);
    document.addEventListener('fullscreenchange', fullscreenChangeHandler);

    if (autoPlay && playPromise?.current) {
      setTimeout(() => {
        setThumbnail('');
        playPromise.current = video.play();
      }, 500)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
        videoRef, volumeState, activePlaybackRate, currentTimeLine,
        timeChangeHandler, pipEnterHandler, pipExitHandler, keyEventHandler,
        fullscreenChangeHandler, autoPlay, setThumbnail, card
       ]
  );

  const errorHandler = useCallback(() => {
    const video = videoRef?.current;

    video.error && setVideoError(video.error);
  }, [videoRef]);

  useEffect(() => {
    return () => {
      document.removeEventListener('fullscreenchange', fullscreenChangeHandler);
      document.removeEventListener('keydown', keyEventHandler);
    };
  }, [fullscreenChangeHandler, keyEventHandler]);

  useEffect(() => {
    if (video?.subtitles) {
      const blob = new Blob([video.subtitles], { type: 'text/vtt' });
      const url = URL.createObjectURL(blob);

      setSubtitleUrl(url);
    }
  }, [video?.subtitles]);

  const handleMouseEnter = useCallback(() => {
    if (activeCue?.line) activeCue.line = -6;
    for (let i = 0; i < track?.cues?.length; i++) {
      track.cues[i].line = -6;
      if (fullscreenState) track.cues[i].line = -8;
    }
  }, [activeCue, fullscreenState, track]);

  const handleMouseLeave = useCallback(() => {
    if (activeCue?.line) activeCue.line = -1;
    for (let i = 0; i < track?.cues?.length; i++) {
      track.cues[i].line = -2;
    }
  }, [activeCue, track]);

  const toggleSubtitleHandler = () => {
    setSubtitlesState(prev => !prev);

    if (subtitlesState) handleMouseEnter();
  };

  useEffect(() => {
    setSubtitlesReady(false);
    const video = videoRef?.current;
    setTrack(video.textTracks[0]);


    if (track?.activeCues?.[0]) {
      setActiveCue(track.activeCues[0]);
    }

    if (displayControls) {
      handleMouseEnter()
    } else {
      handleMouseLeave();
    }
    setSubtitlesReady(true);
  }, [displayControls, fullscreenState, videoRef, currentTimeProgress, activeCue, track, handleMouseEnter, handleMouseLeave]);

  return (
    <div
      className="vp-container"
      ref={videoContainerRef}
      style={{ cursor: displayControls ? 'default' : 'none' }}
      onMouseMove={showControlsHandler}
      onMouseLeave={hideControlsHandler}
    >
      {thumbnail && <img className="thumbnail" src={thumbnail} alt="Video Poster" onClick={togglePlayHandler}></img>}
      <video
        className={cn({'playback-paused': playbackState})}
        ref={videoRef}
        src={src}
        controls={false}
        type="video/mp4"
        onLoadedMetadata={videoLoadedHandler}
        onClick={togglePlayHandler}
        onPlay={videoPlayHandler}
        onPause={videoPauseHandler}
        onVolumeChange={volumeChangeHandler}
        onTimeUpdate={timeChangeHandler}
        onDoubleClick={toggleFullscreenHandler}
        onSeeking={showLoaderHandler}
        onSeeked={hideLoaderHandler}
        onWaiting={showLoaderHandler}
        onCanPlay={hideLoaderHandler}
        onError={errorHandler}
        tabIndex="0"
      >
        {subtitleUrl && subtitlesState && subtitlesReady &&
          <track
            id='subtitles'
            className={cn('track')}
            src={subtitleUrl}
            kind="subtitles"
            srcLang={language}
            label={languagesLocale[language]}
            default={true}
          />
        }
      </video>
      <Loader on={displayLoader} />
      <KeyAction
        ref={videoKeyActionRef}
        on={volumeKeyAction}
        volume={volumeState}
      />
      <Error error={videoError} />
      <div className={`vp-controls${!displayControls ? ' hide' : ''}`}>
        <Dropdown
          on={displayDropdown}
          playbackRates={playbackRates}
          activePlaybackRate={activePlaybackRate}
          onClose={setDisplayDropdown}
          onChangePlaybackRate={changePlaybackRateHandler}
          resolutions={resolutions}
          currentResolution={currentResolution}
          setCurrentResolution={setCurrentResolution}
          setCurrentTimeLine={setCurrentTimeLine}
          currentTimeProgress={currentTimeProgress}
          onToggle={toggleDropdownHandler}
        />
        <div className="vp-controls__header">
          <Time time={currentTimeUI} />
          <Progress
            bufferProgress={bufferProgress}
            currentProgress={currentProgress}
            videoDuration={videoDuration}
            seekProgress={seekProgress}
            seekTooltip={seekTooltip}
            seekTooltipPosition={seekTooltipPosition}
            onHover={seekMouseMoveHandler}
            onSeek={(allowRewind || forward) && seekInputHandler}  // Allow rewind
            allowRewind={(allowRewind || forward)}
          />
          <Time time={remainedTimeUI} />
        </div>
        <div className="vp-controls__body">
          <div className="vp-controls__volume">
            <Volume
              volume={volumeState}
              onToggle={toggleMuteHandler}
              onSeek={volumeInputHandler}
            />
          </div>
          <div className="vp-controls__play">
            <Rewind onRewind={rewindHandler} />
            <Playback isPlaying={playbackState} onToggle={togglePlayHandler} />
           {(allowRewind || forward) && <Skip onSkip={skipHandler} />}
          </div>
          <div className="vp-controls__settings">
            <Subtitles
              isSubtitles={subtitleUrl ? subtitlesState : false}
              onToggle={toggleSubtitleHandler}
              disabled={!subtitleUrl}
            />
            <Settings onToggle={toggleDropdownHandler} />
            <Pip isPipMode={pipState} onToggle={togglePipHandler} />
            <Fullscreen
              isFullscreen={fullscreenState}
              onToggle={toggleFullscreenHandler}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Player;

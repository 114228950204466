import React, { useEffect, useRef, useState } from 'react';
import cn from 'classnames';
import './SideBar.css';
import player from '../../images/Group.svg'
import check from '../../images/Check Circle.svg'
import { config } from '../../config/config';

export const SideBar = ({
  windowWidth, isMobileMenuOpen, setIsMobileMenuOpen, videos, video, setVideo, setCurrentTimeLine
}) => {
  const { screenWidth } = config;

  const activeItemRef = useRef(null);
  const [categories, setCategories] = useState([]);

  useEffect(() => {
    if (videos?.length) {
      const categories = videos.reduce((acc, v) => {
        const categoryName = v?.categoryName;
        if (!acc.includes(categoryName)) acc.push(categoryName);
        return acc;
      }, []);
      setCategories(categories);
    }
  }, [videos]);

  useEffect(() => {
    if (activeItemRef.current) {
      setTimeout(() => {
        activeItemRef.current.scrollIntoView({ behavior: 'smooth', block: 'center' });
      }, 100)
    }
  }, [video]);

  return (
    <aside className={cn('sidebar', {
      'sidebar-desktop': windowWidth > screenWidth && !isMobileMenuOpen,
      'sidebar-mobile-close': windowWidth <= screenWidth && !isMobileMenuOpen,
      'sidebar-mobile-open': isMobileMenuOpen,
      'sidebar-hide': videos?.length <= 1
    })}>
      <div className='bar bar-category'>
        <ul className='bar-list'>
          {categories.map(category => (
            <li key={category}>
              <h3>{category?.toUpperCase()}</h3>
              <ul>
                {videos
                  .filter(v => v.categoryName === category)
                  .map(v => {
                    const isActive = video?.templateId === v?.templateId;

                    return (!!v?.assets?.length &&
                      <li key={v.templateId} className='bar-item'>
                        <button
                          className={cn('bar-link', {
                            'bar-link-active sync-link-active': isActive,
                          })}
                          ref={isActive ? activeItemRef : null}
                          onClick={() => {
                            setCurrentTimeLine(0)
                            setVideo(v)
                            setIsMobileMenuOpen(false)
                          }}
                        >
                          {v?.videoName}
                          {!v?.isLandingViewed
                            ? <img src={player} alt='play' />
                            : <img src={check} alt='check' />
                          }
                        </button>
                      </li>
                    )}
                  )
                }
              </ul>
            </li>
          ))}
        </ul>
      </div>
    </aside>
  )
}

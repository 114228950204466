import { useState, useCallback } from 'react';

export const useHttp = (token) => {
  const [loading, setLoading] = useState(false);
  const [secondaryLoading, setSecondaryLoading] = useState(false);

  const request = useCallback(async (
    url,
    method = 'GET',
    body = null,
    headers = { Authorization: `Bearer ${token}` }
  ) => {
    setLoading(true);
    
    try {
      if (body) {
        body = JSON.stringify(body)
        headers['Content-Type'] = 'application/json'
      }

      const response = await fetch(url, {method, body, headers})
      const data = await response.json();

      setLoading(false)

      return data
    } catch (e) {
      setLoading(false)
      throw e
    }
  }, [token])
  
  const requestWithSecondaryLoading = useCallback(async (
    url,
    method = 'GET',
    body = null,
    headers = { Authorization: `Bearer ${token}` }
  ) => {
    setSecondaryLoading(true);
    
    try {
      if (body) {
        body = JSON.stringify(body)
        headers['Content-Type'] = 'application/json'
      }

      const response = await fetch(url, {method, body, headers})
      const data = await response.json();

      setSecondaryLoading(false);

      return data
    } catch (e) {
      setSecondaryLoading(false);
      throw e
    }
  }, [token])


  return { loading, secondaryLoading, request, requestWithSecondaryLoading }
}

import React, { useEffect, useRef } from 'react';
import cn from 'classnames';
import player from '../../images/Group.svg';
import check from '../../images/Check Circle.svg';
import lockVideo from '../../images/icons/noun-video.svg';
import { config } from '../../config/config';
import './SideBar.css';

export const FasttrackComplianceSideBar = ({
  windowWidth, isMobileMenuOpen, setIsMobileMenuOpen, videos, video, setVideo, setCurrentTimeLine
}) => {
  const { screenWidth } = config;
  const activeItemRef = useRef(null);

  useEffect(() => {
    if (activeItemRef.current) {
      setTimeout(() => {
        activeItemRef.current.scrollIntoView({ behavior: 'smooth', block: 'center' });
      }, 100)
    }
  }, [video]);

  return (
    <aside className={cn('sidebar', {
      'sidebar-desktop': windowWidth > screenWidth && !isMobileMenuOpen,
      'sidebar-mobile-close': windowWidth <= screenWidth && !isMobileMenuOpen,
      'sidebar-mobile-open': isMobileMenuOpen,
      'sidebar-hide': videos?.length <= 1
    })}>
      <div className='bar'>
        <ul className='bar-list'>
          {videos.map((v, index) => {
            const itemDisabled = (!v.isLandingViewed
              && video?.templateId !== v?.templateId
              && index !== 0
              && !videos?.[index - 1]?.isLandingViewed)
              || (videos?.slice(0, index).some(v => !v.isLandingViewed) && !v.isLandingViewed);

            const isActive = video?.templateId === v?.templateId;

            return (
              v?.assets?.length &&
                <li key={v.templateId} className='bar-item'>
                  <button
                    ref={isActive ? activeItemRef : null}
                    className={cn('bar-link', {
                      'bar-link-active sync-link-active': isActive,
                      'bar-link-disabled': itemDisabled
                    })}
                    onClick={() => {
                      setCurrentTimeLine(0);
                      setVideo(v);
                      setIsMobileMenuOpen(false);
                    }}
                    disabled={itemDisabled}
                  >
                    {v?.videoName}
                    {!v?.isLandingViewed
                      ? itemDisabled
                        ? <img src={lockVideo} alt='lock' />
                        : <img src={player} alt='play' />
                      : <img src={check} alt='check' />
                    }
                  </button>
                </li>
            )})}
        </ul>
      </div>
    </aside>
  );
};

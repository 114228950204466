import { memo } from 'react';
import { useTranslation } from 'react-i18next';

import Btn from './Btn';
import { ReactComponent as TrackSkipIcon } from '../../../images/icons/track-skip.svg';

const Skip = ({ onSkip }) => {
  const { t } = useTranslation();

  return (
    <Btn label={t('+ 10 seconds')} onClick={onSkip}>
      <TrackSkipIcon />
    </Btn>
  );
};

export default memo(Skip);

import React, { useCallback, useEffect, useState, useRef, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import cn from 'classnames';
import Navbar from '../Navbar/Navbar';
import { Footer } from '../Footer/Footer';
import { useParams } from 'react-router-dom';
import { useHttp } from '../../hooks/http.hook';
import { Loader } from '../Loader/Loader';
import { useCrypto } from '../../hooks/crypto.hook';
import { useTitle } from '../../hooks/title.hook';
import { Error } from '../Error/Error';
import { LanguageContext } from '../../context/LanguageContext';
import Player from '../Player/Player';
import './UserPassphrase.css';

export const UserPassphraseSelected = () => {
  const { token, passphraseId } = useParams();
  const videoRef = useRef(null);
  const { decryptData } = useCrypto();
  const { language } = useContext(LanguageContext);
  const { i18n } = useTranslation();

  const { request, loading } = useHttp(token);
  const [error, setError] = useState(null);
  const [pageVisible, setPageVisible] = useState(false);
  const [pageTitle, setPageTitle] = useState('Successfully Enrolled - PhishFirewall Landing Pages');
  const [video, setVideo] = useState({});
  const [passphrase, setPassphrase] = useState('');
  const [currentResolution, setCurrentResolution] = useState(1280);
  const [resolutions, setResolutions] = useState([]);
  const [currentProgress, setCurrentProgress] = useState(0);
  const [currentTimeLine, setCurrentTimeLine] = useState(0);
  const [thumbnail, setThumbnail] = useState('');

  useEffect(() => {
    if (passphrase) setPageTitle(`Successfully Enrolled - ${passphrase} - PhishFirewall Landing Pages`)
  }, [passphrase]);

  useTitle(pageTitle);

  useEffect(() => {
    i18n.changeLanguage('en-US');
  }, [i18n]);

  useEffect(() => {
    setCurrentProgress(0)
    const currVideo = videoRef.current;

    if (currVideo) {
      const assetsResolutions = [...new Set(video?.assets?.map(a => a.width))].sort((a, b) => b - a);
      setResolutions(assetsResolutions);

      let videoUrl = video?.assets?.find(a => a.width === currentResolution)?.url;
      
      if (assetsResolutions?.length && !assetsResolutions.includes(currentResolution)) {
        setCurrentResolution(assetsResolutions.at(0));
        videoUrl = video?.assets?.find(a => a.width === assetsResolutions.at(-1))?.url;
      }

      currVideo.src = videoUrl;
      currVideo.load();
    }
  }, [videoRef, video, currentResolution]);

  const fetchPassphrases = useCallback(async () => {
    try {
      if (token) {
        const fetched = await request(`/api/passphrase/passphrases/${passphraseId}`, 'PATCH', null, {
          Authorization: `Bearer ${token}`
        })

        const decryptRes = decryptData(fetched);

        if (decryptRes?.error) {
          setError(decryptRes);

          return;
        }
        else {
          setVideo(decryptRes.video || {});
          setPassphrase(decryptRes.passphrase || '');
        }

        setPageVisible(true);

        return decryptRes;
      }
    } catch (err) {
      setPageVisible(true);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token]);

  useEffect(() => {
    fetchPassphrases();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className='page'>
      {!error
        ? <>
            <Navbar
              welcomePage={false}
              videos={[]}
              loading={loading}
              language={language}
              passphrasePage={true}
            />

            {!loading && pageVisible
              ? <main className='main'>
                  <div className='passhrase-container passhrase-container-video'>
                    <h3>Congratulations</h3>
                    <h4>You have successfully updated your security phrase!</h4>

                    <div className={cn('video-player-container', {'passhrase-video-player-container': video.thumbnail})}>
                      <Player
                        src={video?.videoUrl}
                        autoPlay={true}
                        videoRef={videoRef}
                        currentProgress={currentProgress}
                        setCurrentProgress={setCurrentProgress}
                        resolutions={resolutions}
                        currentResolution={currentResolution}
                        setCurrentResolution={setCurrentResolution}
                        currentTimeLine={currentTimeLine}
                        setCurrentTimeLine={setCurrentTimeLine}
                        thumbnail={thumbnail}
                        setThumbnail={setThumbnail}
                        allowRewind={true}
                        video={video}
                      />
                    </div>
                  </div>
                </main>
              : <Loader on={true}/>
            }
          </>
        : <>
            <Navbar
              welcomePage={true}
            />
            {!loading
              ? <Error error={error} />
              : <Loader />
            }
          </>
      }

      <Footer />
    </div>
  )
};

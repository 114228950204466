import React, { useContext } from "react";
import Navbar from "../Navbar/Navbar";
import { useTranslation } from 'react-i18next';
import { Footer } from "../Footer/Footer";
import { LanguageContext } from "../../context/LanguageContext";

export const WelcomePage = () => {
  const { t } = useTranslation();
  const { language } = useContext(LanguageContext);

  return (
    <div className='page'>
      <Navbar
        welcomePage={true}
        language={language}
      />

      <main className='main'>
        <div className='welcome-page'>
          <h2>{t('welcome')}</h2>
        </div>
      </main>

      <Footer/>
    </div>
  )
}
import React, { useCallback, useEffect, useState, useContext } from 'react';
import Navbar from '../Navbar/Navbar';
import { Footer } from '../Footer/Footer';
import { NavLink, useParams } from 'react-router-dom';
import { useHttp } from '../../hooks/http.hook';
import { Loader } from '../Loader/Loader';
import { useCrypto } from '../../hooks/crypto.hook';
import { useTitle } from '../../hooks/title.hook';
import { Error } from '../Error/Error';
import { LanguageContext } from '../../context/LanguageContext';
import lora from '../../images/passphrase/Lora.svg';
import './UserPassphrase.css';

export const UserPassphrase = () => {
  useTitle('Account Preferences - PhishFirewall Landing Pages');
  const { token } = useParams();
  const { decryptData } = useCrypto();
  const { language } = useContext(LanguageContext);

  const { request, loading } = useHttp(token);
  const [error, setError] = useState(null);
  const [user, setUser] = useState({});
  const [passphrases, setPassphrases] = useState([]);
  const [imageLoaded, setImageLoaded] = useState(false);

  const fetchPassphrases = useCallback(async () => {
    try {
      if (token) {
        const fetched = await request('/api/passphrase/passphrases', 'GET', null, {
          Authorization: `Bearer ${token}`
        })

        const decryptRes = decryptData(fetched);

        if (decryptRes?.error) {
          setError(decryptRes);

          return;
        }
        else {
          setUser(decryptRes.user || {});
          setPassphrases(decryptRes.passphrases || []);
        }

        return decryptRes;
      }
    } catch (err) { }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token]);

  useEffect(() => {
    fetchPassphrases();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const image = new Image();
    image.onload = () => setImageLoaded(true);
    image.src = lora;
  }, []);

  return (
    <div className='page'>
      {!error
        ? <>
            <Navbar
              welcomePage={false}
              videos={[]}
              loading={loading}
              language={language}
              passphrasePage={true}
            />

            {!loading && imageLoaded
              ? <main className='main'>
                  <div className='passhrase-container'>
                    <div></div>
                    <div className='passhrase-image-options'>
                      <div className='passhrase-image'><img src={lora} alt='Lora avatar'></img></div>
                      <div className='passhrase-title-options'>
                        <div className='passhrase-title'><h2>Choose your security phrase!</h2></div>
                        <ul className='passhrase-options'>
                          {passphrases.map(({name, id}, index) =>
                            (
                              <li
                                key={id}
                                style={{ animationDelay: `${(index + 1) * 0.07 }s` }}
                              >
                                <NavLink
                                  className={'passhrase-options-link'}
                                  to={`/user/${token}/account-preferences/${id}`}>
                                    <button
                                      className='passhrase-option'
                                      disabled={user.personalPassphraseId === id}
                                    >
                                      {name}
                                    </button>
                                </NavLink>
                              </li>
                            )
                          )}
                        </ul>
                      </div>
                    </div>
                    <div className='passhrase-description'>
                      <h3>We Take Security Seriously</h3>
                      <p>
                        Our goal is to provide you with a safe and secure training experience.
                        Cyber Awareness Advocates will use the information provided for security and to customize the learning experience to your unique learning preferences.
                      </p>
                    </div>
                  </div>
                </main>
              : <Loader />
            }
          </>
        : <>
            <Navbar
              welcomePage={true}
            />
            {!loading
              ? <Error error={error} />
              : <Loader />
            }
          </>
      }

      <Footer />
    </div>
  )
};

import { memo } from 'react';
import { useTranslation } from 'react-i18next';

import Btn from './Btn';
import { ReactComponent as SubtitlesIcon } from '../../../images/icons/subtitles.svg';
import { ReactComponent as SubtitlesIconDisabled } from '../../../images/icons/subtitles_disabled.svg';
import { ReactComponent as SubtitlesExitIcon } from '../../../images/icons/subtitles-off.svg';

const Subtitles = ({ isSubtitles, onToggle, disabled }) => {
  const { t } = useTranslation();

  return (<Btn
    label={
      isSubtitles
        ? t('Subtitles Off')
        : !disabled
          ? t('Subtitles')
          : t('Subtitles Unavailable')
    }
    onClick={onToggle}
    disabled={disabled}
  >
    {!isSubtitles && !disabled && <SubtitlesIcon />}
    {!isSubtitles && disabled && <SubtitlesIconDisabled />}
    {isSubtitles && <SubtitlesExitIcon />}
  </Btn>
)};

export default memo(Subtitles);

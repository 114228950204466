import { memo } from 'react';

import Btn from './Btn';
import { ReactComponent as TrackRewindIcon } from '../../../images/icons/track-rewind.svg';
import { useTranslation } from 'react-i18next';

const Rewind = ({ onRewind }) => {
  const { t } = useTranslation();

  return (
    <Btn label={t('- 10 seconds')} onClick={onRewind}>
      <TrackRewindIcon />
    </Btn>
  );
};

export default memo(Rewind);

import { memo } from 'react';
import { useTranslation } from 'react-i18next';

import Btn from './Btn';
import { ReactComponent as PlayIcon } from '../../../images/icons/play.svg';
import { ReactComponent as PauseIcon } from '../../../images/icons/pause.svg';

const Playback = ({ isPlaying, onToggle }) => {
  const { t } = useTranslation();

  return (
  <Btn label={isPlaying ? t('Pause') : t('Play')} onClick={onToggle}>
    {isPlaying ? <PauseIcon /> : <PlayIcon />}
  </Btn>
)};

export default memo(Playback);

import Btn from './Btn';
import { ReactComponent as PipInIcon } from '../../../images/icons/pip-in.svg';
import { ReactComponent as PipOutIcon } from '../../../images/icons/pip-out.svg';
import { useTranslation } from 'react-i18next';

const Pip = ({ isPipMode, onToggle }) => {
  const { t } = useTranslation();

  return (
    <Btn label={t('Picture in Picture')} onClick={onToggle}>
      {isPipMode ? <PipOutIcon /> : <PipInIcon />}
    </Btn>
  );
};

export default Pip;

import { memo } from 'react';
import { useTranslation } from 'react-i18next';

import Btn from './Btn';
import { ReactComponent as SettingIcon } from '../../../images/icons/gear.svg';

const Settings = ({ onToggle }) => {
  const { t } = useTranslation();

  return (
    <Btn label={t('Settings')} onClick={onToggle}>
      <SettingIcon />
    </Btn>
  );
};

export default memo(Settings);

import React from "react";
import cn from 'classnames';
import { useTranslation } from 'react-i18next';
import './Head.css';

export const Head = ({ isMobileMenuOpen, videos, templateVideo, firstName, categoryName }) => {
  const unwatchedVideos = videos?.filter(v => !v?.isLandingViewed)?.length;
  const { t } = useTranslation();

  return (
    <div className={cn('head', { 'head-hide': isMobileMenuOpen })}>
      <h3>{t('hello')}, {firstName}</h3>
      {!templateVideo
        ? unwatchedVideos > 0
          ? categoryName
            ? <p>
                {t('unwatched_description_7')} <strong>{categoryName}</strong> {t('unwatched_description_8')} {unwatchedVideos} {unwatchedVideos > 1 ? t('unwatched_description_3') : t('unwatched_description_4')}<br />
                {unwatchedVideos > 1 ? t('unwatched_description_6') : ''}<br/>
                {t('great_lora_2')} 
              </p>
             : <p>
                 {t('unwatched_description_1')} {unwatchedVideos} {t('unwatched_description_2')} {unwatchedVideos > 1 ? t('unwatched_description_3') : t('unwatched_description_4')} {t('unwatched_description_5')}
                 {unwatchedVideos > 1 ? t('unwatched_description_6') : ''}<br/>
                 {t('great_lora_2')}
               </p>
          : <p>
              {t('great_lora_1')}<br/>
              {t('great_lora_2')}
            </p>
        : videos?.description && <p>{videos?.description}</p>
      }
    </div>
  )
};

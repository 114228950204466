import { memo } from 'react';
import cn from 'classnames';

const Progress = ({
  bufferProgress,
  currentProgress,
  videoDuration,
  seekProgress,
  seekTooltipPosition,
  seekTooltip,
  onHover,
  onSeek,                            // Allow rewind
  allowRewind
}) => {
  return (
    <div className="vp-progress">
      <div className="vp-progress__range">
        <div className="vp-progress__range--background" />
        <div
          className="vp-progress__range--buffer"
          style={{ width: bufferProgress + '%' }}
        />
        <div
          className="vp-progress__range--current"
          style={{ width: currentProgress + '%' }}
        >
          <div className="vp-progress__range--current__thumb" />
        </div>
        <input
          className={cn("vp-progress__range--seek", { 'vp-progress__range--seek--active': allowRewind })}
          type="range"
          step="any"
          max={videoDuration}
          defaultValue={seekProgress}
          onMouseMove={onHover}
          onChange={allowRewind && onSeek}          // Allow rewind
        />
      </div>

      <span
        className={cn('vp-progress__tooltip', { 'vp-progress__tooltip--active': allowRewind })}
        style={{ left: seekTooltipPosition }}
      >
        {seekTooltip}
      </span>
    </div>
  );
};

export default memo(Progress);

import React from "react";
import { useTranslation } from 'react-i18next';

import logo from '../../images/PhishFirewall_logo_grey.svg';
import './Footer.css';

export const Footer = () => {
  const { t } = useTranslation();

  return (
    <footer id='footer'>
      <a href="https://www.phishfirewall.com" target="_blank" rel="noreferrer"><img className='footer-logo' src={logo} alt='Logo'></img></a>
      <a href="https://www.phishfirewall.com/privacy-policy" target="_blank" rel="noreferrer">{t('privacy_policy')}</a>
    </footer>
  )
};

import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import Backend from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';
import enTranslation from './locale/en.json';
import esTranslation from './locale/es.json';
import frTranslation from './locale/fr.json';
import deTranslation from './locale/de.json';
import itTranslation from './locale/it.json';
import trTranslation from './locale/tr.json';
import jaTranslation from './locale/ja.json';
import zhTranslation from './locale/zh.json';
import hiTranslation from './locale/hi.json';
import arTranslation from './locale/ar.json';
import elTranslation from './locale/el.json';
import ptTranslation from './locale/pt.json';

i18n
  .use(Backend)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources: {
      en: {
        translation: enTranslation,
      },
      es: {
        translation: esTranslation,
      },
      fr: {
        translation: frTranslation,
      },
      de: {
        translation: deTranslation,
      },
      it: {
        translation: itTranslation,
      },
      tr: {
        translation: trTranslation,
      },
      ja: {
        translation: jaTranslation,
      },
      zh: {
        translation: zhTranslation,
      },
      hi: {
        translation: hiTranslation,
      },
      ar: {
        translation: arTranslation,
      },
      el: {
        translation: elTranslation,
      },
      pt: {
        translation: ptTranslation,
      },
    },
    fallbackLng: 'en',
    // debug: true,

    interpolation: {
      escapeValue: false,
    }
  });

export default i18n;

import React, { useEffect, useState } from "react";
import { useTranslation } from 'react-i18next';
import { useTitle } from "../../hooks/title.hook";
import './Error.css';

export const Error = ({ error }) => {
  useTitle('PhishFirewall Training | Error');
  const { t } = useTranslation();
  const [dots, setDots] = useState('');

  useEffect(() => {
    if (error) {
      const interval = setInterval(() => {
        setDots((prevDots) => (prevDots.length === 3 ? '' : prevDots + '.'));
      }, 500);

      return () => clearInterval(interval);
    }
  }, [error]);

  return (
    <main className='main'>
      <div className='welcome-page'>
        <h2>{t('error')} <b>{error.code}</b></h2>
        <div>
          <img
          className='error-img'
            src='https://cdn.prod.website-files.com/63d7fedf2c6922165de976ba/641dd2bb1317cb7d0f0374dc_little%20padlock.png'
            alt='Error'
          />
        </div>
        <h2 className='error-message'>{t('error_message')}<span>{dots}</span></h2>
      </div>
    </main>
  )
};

export const config = {
  screenWidth: 800,
  languagesLocale: {
    en: "English",
    fr: "Français",
    es: "Español",
    de: "Deutsch",
    it: "Italiano",
    tr: "Türkçe",
    ja: "日本語",
    zh: "中文",
    hi: "हिन्दी",
    ar: "العربية",
    el: "Ελληνικά",
    pt: "Português",
  },
  languages: {
    en: "English",
    fr: "French",
    es: "Spanish",
    de: "German",
    it: "Italian",
    tr: "Turkish",
    ja: "Japanese",
    zh: "Chinese",
    hi: "Hindi",
    ar: "Arabic",
    el: "Greek",
    pt: "Portuguese",
  },
};

import React, { useEffect, useRef, useState } from "react";
import cn from 'classnames';
import logo from '../../images/PhishFirewall_logo.svg';
import { Head } from "../Head/Head";
import { config } from "../../config/config";
import lang from '../../images/icons/lang.svg'
import './Navbar.css';

const Navbar = ({
  setIsMobileMenuOpen, isMobileMenuOpen, welcomePage, videos, loading, toggleVideoPlay,
  templateVideo, language, passphrasePage, firstName, categoryName
}) => {
  const optionRef = useRef();
  const [optionsShowed, setOptionsShowed] = useState(false);
  const { languagesLocale } = config;
  const options = Object.entries(languagesLocale)

  const toggleMobileMenu = () => {
    !isMobileMenuOpen && toggleVideoPlay();
    setIsMobileMenuOpen(!isMobileMenuOpen);
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (optionRef.current && !optionRef.current.contains(event.target)) {
        setOptionsShowed(false);
      }
    };

    document.addEventListener('click', handleClickOutside);

    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, []);

  const languageHandler = (lng) => {
    if (lng !== language) {
      setTimeout(() => {
        const url = new URL(window.location.href);
        url.searchParams.set('lng', lng);
        window.location.href = url.toString();
      }, 300)
    }
    setOptionsShowed(false);
  }

  const changeOptionsShowed = () => {
    setOptionsShowed(!optionsShowed);
  };

  return (
    <>
      <header className={cn('header', {
        'header-template': templateVideo,
        'header-passphrase': passphrasePage
        })}>
        <img className='nav-logo' src={logo} alt='Logo'></img>
        {!passphrasePage &&
          <div className="menu-container">
            <div ref={optionRef} className="language-container">
              {(languagesLocale[language] || languagesLocale['en']) &&
                <button
                  onClick={changeOptionsShowed}
                  className={cn(`button-language button-language-${language}`, {'button-language--active': optionsShowed})}
                >
                  <img src={lang} alt="Lang"></img>
                  {(!!languagesLocale[language] && language.toUpperCase()) || 'EN'}
                </button>
              }
              <ul
                className={cn({
                  'options-showed': language && optionsShowed,
                  'options-hide': language && !optionsShowed,
                  'options-none': !language && !optionsShowed
                })}
              >
                {options.map((option, i) => (
                  <li
                    className={`option-${option[0]}`}
                    role='button'
                    key={i}
                    onClick={() => languageHandler(option[0])}
                  >
                    {option[1]}
                  </li>
                ))}
              </ul>
            </div>
            {((videos?.length > 1 && !welcomePage && !templateVideo) || loading) &&
              <div
                className={cn('mobile-menu-button', {
                  'open': isMobileMenuOpen
                })}
                role='button'
                onClick={toggleMobileMenu}
                disabled={loading}
              >
                <span></span>
                <span></span>
                <span></span>
                <span></span>
              </div>
            }
          </div>
        }
      </header>

      {((!welcomePage && !loading && !passphrasePage) || (templateVideo && !loading && !passphrasePage)) &&
        <Head
          isMobileMenuOpen={isMobileMenuOpen}
          videos={videos}
          templateVideo={templateVideo}
          firstName={firstName}
          categoryName={categoryName}
        />
      }
    </>
  )
};

export default Navbar;
import { memo } from 'react';
import { useTranslation } from 'react-i18next';

import Btn from './Btn';
import { ReactComponent as FullscreenIcon } from '../../../images/icons/fullscreen.svg';
import { ReactComponent as FullscreenExitIcon } from '../../../images/icons/fullscreen-exit.svg';

const Fullscreen = ({ isFullscreen, onToggle }) => {
  const { t } = useTranslation();

  return (<Btn
    label={isFullscreen ? t('Fullscreen Off') : t('Fullscreen')}
    onClick={onToggle}
  >
    {!isFullscreen && <FullscreenIcon />}
    {isFullscreen && <FullscreenExitIcon />}
  </Btn>
)};

export default memo(Fullscreen);

import React from 'react';
import './Loader.css';

export const Loader = React.memo(() => (
  <div className="lds-roller">
    <div className="lds-spinner">
      <div/>
      <div/>
      <div/>
      <div/>
      <div/>
      <div/>
      <div/>
      <div/>
      <div/>
      <div/>
      <div/>
      <div/>
    </div>
  </div>
));
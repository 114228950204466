import React, { useRef } from 'react';
import { ReactComponent as IconClose } from '../../images/icons/close-button.svg';
import { Transition } from 'react-transition-group';
import './Modal.css';

export const CustomModal = ({ isOpen, onClose, children }) => {
  const wrapperRef = useRef(null);

  const onWrapperClick = event => wrapperRef?.current === event?.target && onClose();

  return (
    <Transition
      in={isOpen}
      timeout={350}
      unmountOnExit={true}
      nodeRef={wrapperRef}
    >
      {state => (
        <div className={`modal-overlay modal--${state}`}>
          <div
            className="modal-wrapper"
            ref={wrapperRef}
            onClick={onWrapperClick}
          >
            <div className="modal-content">
              <button className="modal-close-button" onClick={onClose}>
                <IconClose />
              </button>
              {children}
            </div>
          </div>
        </div>
      )}
    </Transition>
  );
};

import React, { useEffect, useState, Suspense } from 'react';
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer, toast } from 'react-toastify';
import { Navigate, Route, BrowserRouter as Router, Routes, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { MainPage } from './components/MainPage/MainPage';
import { WelcomePage } from './components/WelcomePage/WelcomePage';
import { config } from './config/config';
import { TrainingPage } from './components/TrainingPage/TrainingPage';
import { Error } from './components/Error/Error';
import { LanguageContext } from './context/LanguageContext';
import { UserPassphrase } from './components/UserPassphrase/UserPassphrase';
import { UserPassphraseSelected } from './components/UserPassphrase/UserPassphraseSelected';
import { FastTrackCompliance } from './components/FastTrackCompliance/FastTrackCompliance';
import './styles/App.css';

const MandatoryCompliance = () => {
  const { token } = useParams();
  return <Navigate to={`/user/${token}/compliance`} />;
};

export const App = () => {
  const { screenWidth } = config;
  const { i18n } = useTranslation();

  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [language, setLanguage] = useState('');
  const [tsna, setTsna] = useState(false);

  useEffect(() => {
    if (tsna) i18n.changeLanguage('en-US');
    setLanguage(i18n?.language?.split('-')?.at(0)?.toLowerCase());
  }, [i18n, tsna]);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
      if (window.innerWidth > screenWidth) setIsMobileMenuOpen(false);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [screenWidth]);

  const showToastMessage = (info) => {
    toast.error(info, {
      position: toast.POSITION.TOP_LEFT,
      autoClose: 5000,
      className: 'toast-message-info'
    });

    return toast
  };

  return (
    <Suspense fallback="loading">
      <LanguageContext.Provider value={{ language, setTsna, showToastMessage } }>
        <Router>
          <Routes>
            <Route
              path="/user/:token"
              element={<MandatoryCompliance />}
            />
            <Route exact path="/user/:token/compliance" element={
              <MainPage
                isMobileMenuOpen={isMobileMenuOpen}
                setIsMobileMenuOpen={setIsMobileMenuOpen}
                windowWidth={windowWidth}
              />
            }/>
            <Route exact path="/user/:token/fasttrack/:categoryId" element={
              <FastTrackCompliance
                isMobileMenuOpen={isMobileMenuOpen}
                setIsMobileMenuOpen={setIsMobileMenuOpen}
                windowWidth={windowWidth}
              />
            }/>
            <Route exact path="/user/:token/tlp/:videoId" element={<TrainingPage />} />
            <Route exact path="/user/:token/account-preferences" element={<UserPassphrase />} />
            <Route exact path="/user/:token/account-preferences/:passphraseId" element={<UserPassphraseSelected />} />
            <Route exact path="/error" element={<Error error={{ error: "Don't authorized", code: 404 }} />} />
            <Route exact path="/" element={<WelcomePage />}/>
            <Route path="*" element={<Navigate to="/error" />} />
          </Routes>
        </Router>
        <ToastContainer />
      </LanguageContext.Provider>
    </Suspense>
  );
};
